import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import LogoSeattleStorm from "../../../images/third-party/seattle-storm/seattle-storm-logo.svg";
import IconBasketball from "../../../images/third-party/seattle-storm/icon-basketball.svg";
import IconOwl from "../../../images/third-party/seattle-storm/icon-owl.svg";
import IconTicket from "../../../images/third-party/seattle-storm/icon-ticket.svg";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const WaFdSportsSeattleStorm = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-storm/hero-seattle-storm-050123-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "seattle-storm-hero",
    ...getHeroImgVariables(imgData),
    altText: "Seattle Storm team high-five at Denali Basketball Clinic.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Seattle Storm Teams Up with WaFd Bank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/wafd-sports",
      title: "WaFd Sports"
    },
    {
      id: 3,
      active: true,
      title: "Seattle Storm"
    }
  ];

  const title = "Seattle Storm Teams Up with WaFd Bank";
  const description =
    "Learn how WaFd Bank and Seattle Storm are partnering to launch Fit Futures, a community initiative that supports kids' physical activity & financial education.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/wafd-sports/seattle-storm"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-seattle-storm-050123.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row">
          <div className="col-md-9">
            <h1 className="text-success">Seattle Storm</h1>
            <h4>
              WaFd Bank is proud to support women's sports, starting with the Seattle Storm. With this partnership, WaFd
              Bank and the Seattle Storm will launch Fit Futures, a community initiative that will provide Title One
              schools with equal access to physical activity and financial literacy education as well as a half court
              shootaround challenge with Storm players benefitting a local BIPOC non-profit.
            </h4>
          </div>
          <div className="col-md-3 text-center">
            <img src={LogoSeattleStorm} width={175} height={165} className="" alt="Seattle Storm Logo" />
          </div>
        </div>
      </section>

      <section className="bg-green-60">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/third-party/seattle-storm/thumbnail-seattle-storm-051023.jpg"
                alt="Man using laptop at home"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6 text-white">
              <h4>
                "We are thrilled to partner with the Storm to address equitable access to physical fitness within our
                area schools and to be given an opportunity to live our mission to make a difference by offering a
                top-quality financial literacy platform to our community for free. We look forward to kicking off the
                Fit Futures program this fall while supporting women's professional sports."
              </h4>
              <h5 className="font-weight-bold">
                Cathy Cooper, Executive Vice President and Chief Consumer Banker at WaFd Bank
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h2 className="text-green-60">Fit Futures supports our communities with:</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-md-0 d-flex">
              <img src={IconBasketball} width={48} height={48} className="mr-3" alt="Seattle Storm Logo" />
              <p>
                <span className="font-weight-bold">School assembly and WaFd Bank Foundation grant:</span> three Title 1
                schools will receive $5,000 each towards the purchase of new PE equipment and supplies in a celebratory
                ceremony that will include the presence of Storm mascot Doppler and a Storm VIP guest.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-md-0 d-flex">
              <img src={IconOwl} width={48} height={48} className="mr-3" alt="Seattle Storm Logo" />
              <p>
                <span className="font-weight-bold">Financial literacy:</span> by use of Banzai, an award-winning,
                interactive content platform that uses real-world scenarios to teach finance at any school grade. WaFd
                Bank employees will also be available to lead in-person workshops with students.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-md-0 d-flex">
              <img src={IconTicket} width={48} height={48} className="mr-3" alt="Seattle Storm Logo" />
              <div>
                <p>
                  <span className="font-weight-bold">Ticket distribution:</span> each of the three selected schools will
                  receive 300 tickets to a regular season Storm game for kids and their families.
                </p>
                <p>Fit Futures tip-off is slated for Fall 2023, following summer break.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default WaFdSportsSeattleStorm;
